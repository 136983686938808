import React, { useEffect, useState } from "react";
import { isAfter } from 'date-fns';

import { LinkModal } from "kh-common-components";

import Header from "components/Header";
import Footer from "components/Footer";

import LayoutMain from "components/layout/layoutMain";
import SEO from "components/Seo";
import Faq from 'components/common/Faq';

import tenMILLP10KP from "images/flybuys-win/flybuys-10m-10k.svg";

import twoMFlybuys from "images/flybuys-win/flybuys-2m.svg";
import fiveHKFlybuys from "images/flybuys-win/flybuys-500k.svg";
import eightyKFlybuys from "images/flybuys-win/flybuys-80k.svg";

import flybuysSuitcase from "images/flybuys-win/flybuys-suitcase-hero.png";
import fruitBasket from "images/flybuys-win/flybuys-basket.png";
import flybuysBananas from "images/flybuys-win/flybuys-hero-bananas.png";
import flybuysPlane from "images/flybuys-win/flybuys-plane.png";

import flybuysPoints10k1pt from "images/flybuys-win/flybuys-10k-1pt.svg";

import { modalContents } from "constants/modal-content";
import faqDataOffer from 'constants/components/accordion-list/flybuys-win';

import "styles/pages/flybuys-win.css";

function FlybuysWin() {
  const [promoCode, setPromoCode] = useState('FLYBUYS10M');
  const [promoEnded, setPromoEnded] = useState(false);
  const [seoTitle, setSeoTitle] = useState('Flybuys Competition – Kleenheat');
  const [seoDesc, setSeoDesc] = useState('Your chance to win a share of 10 million Flybuys points^. Switch to our Monthly Energiser plan to enter. Offer ends 5 May 2024. Terms & conditions apply.');
  const modalTitle = 'Flybuys offer terms and conditions';

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const flybuysWinPromoCode = urlParams.get('utm_source');

    const currentDate = new Date();
		const dateStrFlybuysWinEnd = process.env.FLYBUYS_WIN_EXPIRE.split('-');

		const dateFlybuysWinEnd = new Date(dateStrFlybuysWinEnd[0], dateStrFlybuysWinEnd[1]-1, dateStrFlybuysWinEnd[2], (dateStrFlybuysWinEnd[3] ? dateStrFlybuysWinEnd[3] : ''), (dateStrFlybuysWinEnd[4] ? dateStrFlybuysWinEnd[4] : ''));

    const isFlybuysWinEnded = isAfter(currentDate, dateFlybuysWinEnd);

    if (
      flybuysWinPromoCode &&
      flybuysWinPromoCode.toLowerCase() === 'flybuys'
    ) {
      setPromoCode('FLYBUYSEDM10M');
    }

		if (isFlybuysWinEnded) {
			setPromoEnded(true);
      setSeoTitle('Flybuys offer ended - Kleenheat');
      setSeoDesc('The Flybuys x Kleenheat offer has now ended. Winners will be contacted by 15 May, 2024.');
    }
  }, []);

  return (
    <>
      <SEO title={seoTitle}
           description={seoDesc}
      />
      <Header />
      <LayoutMain>
        <section className="module-wrapper mer-bg--primary-cyan mer-theme--cyan mer-py-lg">
          <div className="container">
            <div className="row mer-panel justify-content-around align-items-middle flex-column">

              <div className="col-12 col-sm-10 col-lg-6 mer-panel__images d-flex align-items-center justify-content-around">
                <img className="mer-panel__image"
                     src={promoEnded ? flybuysSuitcase : tenMILLP10KP}
                     alt={promoEnded ? 'Suitcase' : 'Flybuys points'} />
              </div>

              <div className="col-12 col-sm-10 mer-text--size-lg text-center">
                <div className={promoEnded ? 'd-none' : 'content mer-text--size-lg'}>
                  <h1 className="my-0">Your chance to win a share of <span className="mer-text--no-wrap">10 million</span> <span className="mer-text--no-wrap">Flybuys points</span>.<sup>^</sup></h1>
                  <p><strong>Sign up to our Monthly Energiser plan via this page and link your Flybuys member number to enter. You’ll collect 10,000 bonus Flybuys points<sup>+</sup> and be in the running to win one of 80 prizes.<sup>^</sup></strong></p>
                  <p>Offer ends 11:59pm, 5 May 2024.</p>
                  
                  <div className="col-12 col-sm-8 col-md-6 col-lg-4 mer-panel__actions mer-button-group flex-column mx-auto">
                    <a href={`/sign-up/promo/${promoCode}`}
                      className="mer-button mer-button--primary mx-0 mb-3">
                      Sign up
                    </a>
                    <a href="#offer"
                      className="mer-button mer-button--secondary">
                      Plan details
                    </a>
                  </div>
                </div>

                <div className={promoEnded ? 'content' : 'd-none'}>
                  <div className="content mer-text--size-lg">
                    <h1 className="my-0">Competition closed.</h1>
                    <p><strong>Prizes will be drawn on 8 May 2024.</strong></p>
                    <p>Our lucky prize winners will be contacted on Wednesday 15 May, 2024.<sup>*</sup></p>

                    <p className="mer-typography--body2 mb-0">
                      <LinkModal 
                        modalContents={modalContents}
                        useModal="true"
                        title={modalTitle}
                        linkClass="mer-text--no-wrap"
                        displayContent="Terms and conditions apply"
                        modalTitle={modalTitle}
                        modalContentName="flybuys-win-terms"
                        okayButtonLabel="Okay"
                      />
                    </p>
                  </div>

                  <div className="col-12 col-sm-8 col-md-6 col-lg-4 mer-panel__actions mer-button-group flex-column mx-auto">
                    <a href="/flybuys"
                      className="mer-button mer-button--primary">
                      View current offers
                    </a>
                  </div>

                </div>
                
                <p className={promoEnded ? 'd-none' : 'mb-0 mer-typography--body2'}>
                  <sup>^+</sup>Offer is available to new customers only.&nbsp;
                  <LinkModal 
                    modalContents={modalContents}
                    useModal="true"
                    title={modalTitle}
                    linkClass="mer-text--no-wrap"
                    displayContent="Terms and conditions apply"
                    modalTitle={modalTitle}
                    modalContentName="flybuys-win-terms"
                    okayButtonLabel="Okay"
                  />
                </p>
              </div>
            </div>
          </div>
        </section>

        <section id="prizes"
                className="module-wrapper mer-bg--gs-grey-lighter mer-theme--light mer-py-lg">
                  
          <div className="container mer-text--size-lg">

            <div className="row mer-panel justify-content-center">
              <div className="col-12 col-sm-10 col-md-9 col-lg-8 mb-3">
                <div className="content text-center">
                  <h2>What you could win.</h2>
                </div>
              </div>
            </div>

            <div className="row justify-content-between">
              <div className="col-12 col-sm-4 col-md-3 row justify-content-between justify-content-sm-left align-items-end">
                <div className="mer-panel__images col-5 col-sm-12 text-left text-sm-center mb-0 mb-sm-3 pos-rel">
                  <img className="mer-panel__image"
                       src={flybuysPlane}
                       alt="Plane"/>
                  <img className="mer-panel__image w-55 pos-abs"
                       src={twoMFlybuys}
                       alt="Two million Flybuys points"/>
                </div>
                <div className="col-6 col-sm-12 py-0">
                  <div className="content text-left text-sm-center">
                    <h6 className="my-0 mer-color--primary-navy">1st prize</h6>
                    <p className="mt-0">One winner of 2 million Flybuys points, worth $10,000 Flybuys Dollars.<sup>#</sup></p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-4 col-md-3 row justify-content-between justify-content-sm-left align-items-end">
                <div className="mer-panel__images col-5 col-sm-12 text-left text-sm-center mb-0 mb-sm-3 pos-rel">
                  <img className="mer-panel__image"
                       src={flybuysBananas}
                       alt="Bananas sunbathing"/>
                  <img className="mer-panel__image w-55 pos-abs"
                       src={fiveHKFlybuys}
                       alt="500k Flybuys points" />
                </div>
                <div className="col-6 col-sm-12 py-0">
                  <div className="content text-left text-sm-center">
                    <h6 className="my-0 mer-color--primary-navy">2nd prize</h6>
                    <p className="mt-0">4x winners of 500,000 Flybuys points, each worth $2,500 Flybuys Dollars.<sup>#</sup></p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-4 col-md-3 row justify-content-between justify-content-sm-left align-items-end">
                <div className="mer-panel__images col-5 col-sm-12 text-left text-sm-center mb-0 mb-sm-3 pos-rel">
                  <img className="mer-panel__image"
                       src={fruitBasket}
                       alt="Flybuys basket" />
                  <img className="mer-panel__image w-55 pos-abs"
                       src={eightyKFlybuys}
                       alt="80k Flybuys points" />
                </div>
                <div className="col-6 col-sm-12 py-0">
                  <div className="content text-left text-sm-center">
                    <h6 className="my-0 mer-color--primary-navy">3rd prize</h6>
                    <p className="mt-0">75x winners of 80,000 Flybuys points, each worth $400 Flybuys Dollars.<sup>#</sup></p>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <p className="mer-typography--body2 mb-0 text-center">
                  <LinkModal 
                    modalContents={modalContents}
                    useModal="true"
                    title={modalTitle}
                    linkClass="mer-text--no-wrap"
                    displayContent="View full terms and conditions."
                    modalTitle={modalTitle}
                    modalContentName="flybuys-win-terms"
                    okayButtonLabel="Okay"
                  />
                </p>
              </div>
            </div>
          </div>
        </section>

        <section id="offer" 
                 className={promoEnded ? 'd-none' : 'mer-theme--light mer-bg--ui-light mer-py-ju'}>
          <div className="container">

            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-sm-8 col-lg-6 mer-text--size-lg">
                <div className="content text-center">
                  <h2 className="mt-2 mer-typography--headline1 mer-color--primary-cyan">Your natural gas plan.</h2>
                  <p>Enjoy our award-winning service and ongoing value on our Monthly Energiser plan.</p>
                </div>
              </div>
            </div>
            
            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-sm-8 col-md-6 col-lg-5 col-xl-4">
                <div className="mer-card mdc-card mer-plan-card mer-p-de">
                  <h4 className="mer-plan-card__title">Monthly Energiser</h4>
                  <p className="mer-plan-card__description">
                    <span className="mer-plan-card__discount">
                      35
                      <span className="mer-plan-card__discount-percent">%</span>
                    </span>
                    <span className="mer-plan-card__discount-description">
                      off gas usage charges for at least 12 months
                    </span>
                  </p>
                  <ul className="mer-list mer-color--primary-navy m-0 px-2 pt-3 text-center mer-border--top mer-border--top-dashed">
                    <li><strong>Direct debit</strong></li>
                    <li><strong>Easy monthly instalments</strong></li>
                    <li><strong>Email bills</strong></li>
                    <li>No setup or exit costs</li>
                    <li>No lock-in contracts</li>
                  </ul>
                  <div className="content mt-3 mer-pt-sm mer-border--top mer-border--color-gs-lighter text-center mer-border--top-dashed">
                    <strong className="mer-color--primary-blue">PLUS</strong>
                  </div>
                  <div className="col-10 mx-auto pt-0 mer-my-sm">
                    <div className="mer-panel__images d-flex align-items-center">
                      <img
                        className="mer-panel__image"
                        src={flybuysPoints10k1pt}
                        alt="Flybuys points"
                      />
                    </div>
                    <div className="text-center mer-mt-sm text-center">
                      <strong className="mer-color--primary-blue">And an entry to win a share of 10 million Flybuys points.<sup>^</sup></strong>
                    </div>
                  </div>
                  <a href={`/sign-up/promo/${promoCode}`}
                     className="mer-button mer-button--primary mer-button--size-full-width mb-3 mer-text--size-lg">
                    <span className="mer-color--gs-white">Sign up</span>
                  </a>
                  <small className="ng-plan-details text-center">
                    <LinkModal
                      modalContents={modalContents}
                      useModal="true"
                      title="View plan details and non-standard terms"
                      displayContent="View plan details and non-standard terms"
                      modalTitle="View plan details and non-standard terms"
                      modalContentName="plan_details_and_terms_537"
                      okayButtonLabel="Okay"
                    />
                  </small>
                  <small className="ng-plan-details text-center mer-mt-sm">
                  Bonus Flybuys points allocated 90 days after your sign-up application is accepted<sup>+</sup>
                  </small>
                </div>
                <div className="text-center mer-mt-sm">
                  <small>
                    <LinkModal
                      modalContents={modalContents}
                      useModal="true"
                      displayContent="View prices and fees for all natural gas customers"
                      title="View prices and fees for all natural gas customers"
                      modalTitle="View prices and fees for all natural gas customers"
                      modalContentName="standard_prices_and_fees"
                      okayButtonLabel="Close"
                    />
                  </small>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="howitworks"
                className={promoEnded ? 'd-none' : 'module-wrapper mer-bg--gs-grey-lighter mer-theme--light mer-py-lg'}>
                  
          <div className="container mer-text--size-lg">

            <div className="row mer-panel justify-content-center align-items-center">
              <div className="col-12 col-sm-10 col-md-9 col-lg-8">
                <div className="content text-center">
                  <h2>How it works</h2>
                </div>
              </div>
            </div>

            <div className="row mer-panel justify-content-center">
              <div className="col-12 col-sm-10">

                <div className="row mer-panel justify-content-center align-items-center align-items-lg-start">
                  <div className="col-12 col-lg-4">
                    <div className="row">
                      <h3 className="col-2 mer-typography--headline1 my-0 text-center">1</h3>
                      <p className="col-9 my-0">Sign up to our Monthly Energiser plan via this page and enter your Flybuys member number.</p>
                    </div>
                  </div>

                  <div className="col-12 col-lg-4">
                    <div className="row">
                      <h3 className="col-2 mer-typography--headline1 my-0 text-center">2</h3>
                      <p className="col-9 my-0">Lucky prize winners will be contacted on Wednesday 15 May, 2024.<sup>^</sup></p>
                    </div>
                  </div>

                  <div className="col-12 col-lg-4">
                    <div className="row">
                      <h3 className="col-2 mer-typography--headline1 my-0 text-center">3</h3>
                      <p className="col-9 my-0">Receive your 10,000 Flybuys bonus points 90 days after your sign-up application is accepted.<sup>+</sup></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Faq panelBackground="mer-bg--ui-light"
		  			 panelPadding="mer-py-lg"
		  			 id="faq"
		  			 data={faqDataOffer}
             description={<p className="text-center"><LinkModal 
                          modalContents={modalContents}
                          useModal="true"
                          title={modalTitle}
                          linkClass="mer-text--no-wrap"
                          displayContent="View full terms and conditions."
                          modalTitle={modalTitle}
                          modalContentName="flybuys-win-terms"
                          okayButtonLabel="Okay"
                        /></p>} />

        <section id="sign-up" 
                 className={promoEnded ? 'd-none' : 'module-wrapper mer-bg--primary-cyan mer-theme--cyan mer-py-lg'}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-10 col-lg-8">
                <div className="content text-center mer-text--size-lg">
                  <h4 className="mer-color--primary-navy"
                      style={{ textShadow: "none" }}>Take advantage of this exclusive offer.</h4>
                  <a href={`/sign-up/promo/${promoCode}`}
                     className="mer-button mer-button--primary mb-3">
                    <span className="mer-color--gs-white">Sign up</span>
                  </a>
                </div>
              </div>
            </div>                
          </div>
        </section>
      </LayoutMain>
      <Footer />
    </>
  );
}

export default FlybuysWin;
